const Enum = {
  last: function(array){
    return array.slice(-1);
  },
  repeat: function repeat(func, times) {
    func();
    times && --times && repeat(func, times);
  }
}

export default Enum;