import Random from '../util/random.js';
import Enum from '../util/enum.js';

import RunCommand from "./run.js";

export default class {
  constructor(label = 'show') {
    this.label = label;
  }

  gameover(score, e) {
    let result;
    let prize = 0;

    if (e.game.total > score) {
      prize = e.game.pot * e.game.payout;
      e.game.score += 1;
      e.game.money += prize;
      result = "You won!"
    } else {
      result = "House won!"
    }
    e.game.pot = 0;
    e.message = `Your total is ${e.game.total}<br/> Dealers total is ${score}<br/><br/><b>${result}</b>`;
    e.commands = [new RunCommand()];
    return e;
  }

  execute(e) {
    e.tick();
    let totalRolls = e.game.rolls.length;
    let score = 0;


    Enum.repeat(function () {
      score += Random.d20();
    }, totalRolls)

    // if player has a 20, won
    if (e.game.total == 20) {
      score -= 1;
    }

    // if tie, house won
    if (e.game.total == score) {
      score += 1;
    }

    return this.gameover(score,e);
  }
}