<script>
import engine from "./engine.js";
  import game from "./game.js";

  engine.load(game);

  $: state = engine;

  function handleCommand(command) {
    state = command.execute(state);
    return false;
  }
</script>

<main id="container">
  <div id="image">{@html state.image}</div>
  <div id="message">{@html state.message}</div>
  <div id="pinned">{@html state.pins()}</div>
  <div id="buttons">
    {#each state.commands as command}
      <button on:click={handleCommand(command)}>{command.label}</button>
    {/each}
  </div>
</main>

<style>
  #message {
    border: 1px solid;
    border-top: 0px;
    min-height: 150px;
    padding: 10px;
  }

  #pinned {
    display: flex;
    justify-content: space-around;
    border: 1px solid;
    margin-top: 5px;
    align-items: center;
    height: 30px;
    text-transform: uppercase;
    font-size: 14px;
  }
  #buttons {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }
</style>
