
export default class {
  constructor(label="new game"){
    this.label=label;
  }

  execute(e){
    e.tick();
    e.game.rolls = [];
    e.message = "Please bet before your first roll.";
    // if player has no money, give him
    if (e.game.money == 0){
      e.game.money = e.game.initialMoney;
    }

    let response = e.game.defaultCommands();
    response.pop();
    e.commands = response;
    return e;
  }
}