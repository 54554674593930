const engine = {
  message: "engine ready",
  load: function(game){
    this.image = "";
    this.message = "";
    
    if (game.startScreen != undefined) {
      this.image = game.startScreen;
    } else {
      this.message = `<b>${game.name}</b><br/><br/>`;
    }

    if (game.instructions){
      this.message += `${game.instructions}`;
    }

    this.commands = game.initialCommands;
    this.game = game;
    this.turns = 0;
  },
  tick: function(){
    this.image = "";
    this.turns += 1;
    this.commands = this.game.defaultCommands();
  },
  pins: function(){
   let response = "";
   this.game.pins.forEach(pin => {
     response += `<div>${pin}: ${this.game[pin]}</div>`;
   });;
   return response;
  }
};

export default engine;