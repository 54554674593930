export default class  {
  constructor(money=5, pot, bank){    
    if (money == "all") {
      this.bet = "all";
      this.label = "all in";
    } else {
      const increase = Math.round(pot / 10);
      this.bet = money + increase ;
      if (this.bet > bank){
        this.bet = bank;  
      }
      this.label=`bet (${this.bet})`;  
    }
  }

  execute(e){
    if (this.bet == "all"){
      this.bet = e.game.money;
    }
    e.game.money -= this.bet;
    e.game.pot += this.bet;
    const roll = e.game.roll();
    e.message = `You rolled ${roll}.<br/>Your total is ${e.game.total}.`;
    e.tick();
    return e;
  }
}